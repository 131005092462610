<template>
  <dl
    class="flex flex-wrap items-center gap-x-6 gap-y-2 text-sm font-semibold md:text-base"
  >
    <div>
      <dt class="sr-only">Par</dt>
      <dd class="flex items-center gap-2">
        <app-image
          alt="Pollen logo"
          class="h-6 w-6 rounded bg-white p-[2px]"
          src="/images/logo_ico_color_transparent.svg"
        />
        Pollen
      </dd>
    </div>
    <div v-for="item in informationList" :key="item.label">
      <dt class="sr-only">
        {{ item.label }}
      </dt>
      <dd class="flex items-center gap-2">
        <app-icon :icon="item.icon" :size="18" />
        {{ item.value }}
      </dd>
    </div>
  </dl>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  post: {
    created_at: string;
    statistics?: {
      readingTimeInMinutes: number;
    };
  };
}>();

const { t } = useI18n();
const informationList: { label: string; value: string; icon: string }[] = [
  {
    label: t("blog.post.publication_date"),
    value: useI18nDate(properties.post.created_at).value,
    icon: "ph:calendar-blank",
  },
  ...(properties.post.statistics
    ? [
        {
          label: t("blog.post.reading_time"),
          value: `${properties.post.statistics.readingTimeInMinutes}min`,
          icon: "ph:eyeglasses",
        },
      ]
    : []),
];
</script>
